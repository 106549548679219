import React from 'react'


const circle = () => {
  return (
    <div className='relative flex item-center justify-center' >
        <div className='sm:w-[800px] sm:h-[800px] w-[550px] h-[550px] rounded-full first-line:rounded-full bg-black bg-gradient-to-r from-black via-[rgba(0,0,128,0.15)] to-black shadow-[0_100px_70px_rgba(0,0,128,0.50)] absolute rotate-180 top-[200px]'>
        </div>
     </div>
  )
}

export default circle
